<template>
  <div id="homePage">
    <!--顶部导航-->
    <TabbarTopNav :newMessage="newMessage">
      <template slot="avatar"><span></span></template>
    </TabbarTopNav>
    <!--顶部导航-end-->

    <div class="container">
      <!--轮播图-->
      <div class="header" v-if="advertisings.length">
        <swiper
          ref="swiper"
          class="swiper-container"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(item, index) in advertisings"
            :key="index"
          >
            <div
              @click="jumpTo(item)"
              class="bg-cover"
              :style="{backgroundImage: `url('${item.avatarTemp}')`}"
            ></div>
          </swiper-slide>
        </swiper>
      </div>
      <!--轮播图-end-->

      <!--分类-->
      <div class="section section-1">
        <div class="section-title">
          <div class="left">
            <i class="icon icon-vr"></i>
            <div class="name">ホーム</div>
          </div>
        </div>
        <div class="section-content">
          <ul class="list">
            <router-link
              v-for="(item, index) in categories"
              :key="index"
              :to="{name: 'Category', query: {type: item.id}}"
              tag="li"
              class="list-item"
            >
              <van-image class="img" :src="item.avatarTemp" fit="cover"></van-image>
              <div class="title">{{ item.name }}</div>
            </router-link>
          </ul>
        </div>
      </div>
      <!--分类-end-->

      <!--最新-->
      <div class="section section-2">
        <div class="section-title">
          <div class="left">
            <i class="icon icon-news"></i>
            <div class="name">新作</div>
          </div>
          <div class="right" @click="$router.push({name:'Category',query:{sortType:1}})">
            <div class="name">もっと</div>
            <i class="icon icon-arrow"></i>
          </div>
        </div>
        <div class="section-content">
          <swiper
            ref="swiper1"
            class="list"
            :options="swiperOptions1"
          >
            <swiper-slide
              class="list-item"
              :class="[item.type == 1 ? 'list-item-1' : 'list-item-2']"
              v-for="(item, index) in newVos"
              :key="index"
            >
              <router-link :to="{name: 'Detail', query: {id: item.id}}" tag="li">
                <ListItem1 :detail="{...item,coverAcrossTemp:item.coverVerticalTemp}"  @showFun="detailShowFun(item)">
                  <template v-slot:views><span></span></template>
                  <template v-slot:time><span></span></template>
                  <template v-slot:play><span></span></template>
                </ListItem1>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!--最新-end-->

      <!--热门-->
      <div class="section section-3">
        <div class="section-title">
          <div class="left">
            <i class="icon icon-hot"></i>
            <div class="name">人気作品</div>
          </div>
          <div class="right"  @click="$router.push({name:'Category',query:{sortType:2}})">
            <div class="name">もっと</div>
            <i class="icon icon-arrow"></i>
          </div>
        </div>
        <div class="section-content">
          <van-row class="list" type="flex">
            <van-col
              v-for="(item, index) in vos"
              :key="index"
              :span="section3.span"
            >
              <router-link
                class="list-item"
                :to="{name: 'Detail', query: {id: item.id}}"
              >
                <ListItem1 :detail="item"  @showFun="detailShowFun(item)"></ListItem1>
              </router-link>
            </van-col>
          </van-row>
        </div>
      </div>
      <!--热门-end-->
    </div>
    <!--新人领券流程-->
    <van-popup
      class="popup popup-giftBag-1"
      :close-on-click-overlay="false"
      v-model="visible1"
      :style="{'background':`url(${newLift.url}) center / cover no-repeat`}"
    >
      <div class="content">
        <button @click="onCoupons(1, 'confirm')" class="btn btn-confirm">受領（{{couponsInfo.countDown}}）</button>
        <div class="agreement">
          <van-checkbox v-model="couponsInfo.checked" >
            <div class="txt">受領する場合は、サブスクリプション契約を受けます。無料で有料会員サービスを体験できます。無料期間終わった後自動に有料会員に切り替えます。無料サービス期間中いつでも解約可能です。</div>
          </van-checkbox>
        </div>
        <van-icon name="cross" @click="onCoupons(1, 'cancel')" />
      </div>
    </van-popup>
    <van-popup
      class="popup popup-giftBag-2"
      :close-on-click-overlay="false"
      v-model="visible2"
    >
      <div class="content">
        <div class="thumb"></div>
        <div class="tip" v-if="newLift.gradesNames">
          受領したら下記にサービスを無料で体験できますよ
          <br />
          <span v-for="(item,index) in newLift.gradesNames" :key="index">
            {{item}}
          </span>
        </div>
        <div class="content-footer">
          <button @click="onCoupons(2, 'cancel')" class="btn btn-cancel">結構です</button>
          <button @click="onCoupons(2, 'confirm')" class="btn btn-confirm">続く</button>
        </div>
      </div>
    </van-popup>
    <!-- <van-popup
      class="popup popup-bindPay"
      v-model="visible3"
      :close-on-click-overlay="false"
    >
      <div class="close" @click="onCoupons(3,'cancel')">X</div>
      <div class="content">调用第三方接口：填写支付账号信息</div>
      <div class="bottom">
        <van-button class="btn" type="info" @click="onCoupons(3,'confirm')">下一步</van-button>
      </div>
    </van-popup> -->
    <van-popup
      class="popup popup-coupons"
      v-model="visible4"
      :close-on-click-overlay="false"

    >
      <div class="popup-header">
        <div class="popup-title">おめでとうございます！本日のラッキーユーザーになりました</div>
      </div>
      <div class="content">
        <div class="tip">クレジットカードの登録を続いて、お得なクーボンを貰えます</div>
        <ul class="list">
          <li
            class="list-item"
            v-for="(item, index) in [couponList]"
            :key="index"
          >
            <template>
              <div class="left">
                <div class="title text-ellipsis">{{ item.name }}</div>
                <div class="tip text-ellipsis">受領後{{ item.day }}日間有効</div>
              </div>
              <div class="right">
                {{ item.price }}
              </div>
            </template>
          </li>
        </ul>
        <div class="content-footer">
          <button @click="onCoupons(4,'cancel')" class="btn btn-cancel">結構です</button>
          <button @click="onCoupons(4,'confirm')" class="btn btn-confirm">続く</button>
        </div>
      </div>
    </van-popup>
    <!--新人领券流程-end-->

    <!-- 分享与稍后播放弹层 -->
    <PopupShow
      :detail="actions"
      @later="later"
      :showFun="showFun"
      @upDate="upDate"
      @share="share"
    >
    </PopupShow>
  </div>
</template>

<script>
import { TabbarTopNav, ListItem1, PopupShow } from '@/components';
import { Checkbox } from 'vant';
import { mapGetters } from 'vuex';
export default {
  name: 'Home',
  components: {
    'van-checkbox': Checkbox,
    TabbarTopNav,
    ListItem1,
    PopupShow
  },
  computed: {
    ...mapGetters(['htmlWidth']),
    ...mapGetters(['personInfo'])
  },
  data () {
    return {
      detail: {},
      newMember: {},
      newMessage: false, // 新消息
      advertisings: [], // 轮播图 与 优惠券列表
      categories: [], // 一级分类
      newVos: [], // 新作品
      vos: [],
      swiperOptions: {
        slidesPerView: 1.35,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false // 触摸后仍然执行
        }
      },
      swiperOptions1: {
        slidesPerView: 'auto',
        spaceBetween: 0
      },
      section3: {
        span: 24
      },
      couponsInfo: {
        checked: true,
        countDown: 10,
        timer: null
      },
      newLift: {},
      visible1: false, // 新用户领券第一步
      visible2: false, // 新用户领券第二步
      visible3: false, // 绑定支付账户
      visible4: false, // 新用户领券第四步
      couponList: {}, // 新人礼包优惠券列表
      flag: false, // 表示走到第4步并且点击领取之后才可以领走优惠券
      showFun: false, // 稍后观看与分享的弹层
      actions: {}
    };
  },
  mounted () {
    if (this.personInfo.firstLogin === 1) {
    // 加载首页新人礼包
      this.getNewMember();
      this.openCoupons();
    }
    this.onResize();

    // 加载首页信息数据
    this.getDetail();
    this.getMessage();
  },
  watch: {

    /* 监听窗口大小变化 */
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {

    /* 加载首页信息数据 */
    getDetail () {
      // 首页信息展示数据
      this.$http.get('homePage/view').then((res) => {
        let { advertisings, categories, newVos, vos } = res.data || {};
        this.advertisings = advertisings.length > 1 ? advertisings : advertisings.push(...advertisings);
        this.categories = categories;
        this.newVos = newVos;
        this.vos = vos;
      });
    },

    /* 加载首页新人礼包 */
    getNewMember () {
      // 新人礼包数据
      this.$http.get('homePage/newMember').then((res) => {
        this.newLift = res.data;
      });
    },

    /**
     * 领券流程
     * @param step:步骤, type:按钮类型
     */
    onCoupons (step, type) {
      const { popClose, popOpen, $utils } = this;
      switch (step) {
        case 1:
          if (type === 'confirm') {
            if (this.couponsInfo.checked) {

              // 点击领取 调用领取新人礼包接口
              // this.$http.get('homePage/gain/member').then((res) => {
              //   $router.push({
              //     name: 'SubscribePage'
              //   });
              // });
              /* 调用绑卡流程 */
              // this.$http.get('set/createCustomer', {
              //   showLoading: true
              // }).then((res) => {
              //   if (res.data !== 3) {
              //     this.$http.get('set/createSource', {
              //       showLoading: true
              //     }).then(async (res) => {
              //       const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3', { 'locale': 'zh-CN' });
              //       elepay.handleSource(res.data).then((result) => {
              //         // ① 正常処理
              //         console.log(result);
              //         this.$router.push({
              //           name: 'SubscribePage'
              //         });
              //       }).catch((err) => {
              //         console.log(err);
              //       });
              //     });
              //   }
              // });

              this.payFun();
            } else {
              $utils.toast('契約書を確認して回収してください');
              return false;
            }
          }
          if (type === 'cancel') {
            popOpen('visible2');
          }
          popClose('visible1');
          break;
        case 2:
          if (type === 'cancel') {
            this.$http.get('homePage/refuse/to/receive').then((res) => {
            });
          }
          if (type === 'confirm') {
            popOpen('visible3');
          }
          popClose('visible2');
          break;
        case 3:
          // if (type === 'cancel') {
          //   this.$http.get('discount/new/discount', {
          //     params: {
          //       type: 2
          //     }
          //   }).then((res) => {
          //     this.couponList = res.data;
          //     popOpen('visible4');

          //   });
          // }
          // if (type === 'confirm') {
          //   // 点击下一步 调用领取新人礼包接口   暂时默认领取成功
          //   if (this.flag) {
          //     this.$http.post('discount/drawDiscount', { id: this.couponList.id }).then((res) => {});
          //   }
          //   this.$http.get('homePage/gain/member').then((res) => {
          //     $router.push({
          //       name: 'SubscribePage'
          //     });
          //   });
          // }
          // popClose('visible3');
          break;
        case 4:
          // 点击不了 隐藏弹窗 并且提升绑定失败
          if (type === 'cancel') {
            this.$http.get('homePage/refuse/to/receive').then((res) => {
              popClose('visible4');
              $utils.failToast({
                message: 'バインディングに失敗しました'
              });
            });
          }
          // 点击继续 显示支付弹窗
          // 暂时直接显示订阅成功进行替代 支付接口来了在替换
          if (type === 'confirm') {
            this.payFun();
            popClose('visible4');
          }
          break;
      }
    },

    /* 支付 */
    payFun () {
      this.$http.get('homePage/gain/member', {
        showLoading: true
      }).then(async (res) => {
        const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
        elepay.handleSource(res.data).then((result) => {
          if (result.type === 'cancel') {
            console.log('取消支付');
          } else if (result.type === 'success') {
            // ① 正常処理
            this.$router.push({
              name: 'SubscribePage'
            });
          }
        }).catch((err) => {
          console.log(err);
        });
      });
    },
    async payInfo () {
      const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
      elepay.handleSource().then((result) => {
      // ① 正常処理
        console.log(result);
      }).catch((err) => {
        console.log(err);
      });
    },

    /* 获取消息信息 */
    getMessage () {
      const { $http } = this;
      $http.get('homePage/isExistUnread', { customError: true }).then((res) => {
        this.newMessage = res.data;
      });
    },

    /* 打开新人领券 */
    openCoupons () {
      const { couponsInfo, popOpen } = this;
      popOpen('visible1');
      clearInterval(couponsInfo.timer);
      couponsInfo.countDown = 10;
      couponsInfo.timer = setInterval(() => {
        if (couponsInfo.countDown > 0) {
          couponsInfo.countDown--;
        } else {
          clearInterval(couponsInfo.timer);
        }
      }, 1000);
    },

    /* 关闭 */
    popClose (name) {
      this[name] = false;
    },

    /* 打开弹出层 */
    popOpen (name) {
      this[name] = true;
    },


    /* 添加或移除稍后观看视频 */
    later () {
      this.getDetail();
    },

    /* 分享视频 */
    share () {
      this.getDetail();
    },

    /* 详情分类分享 */
    detailShowFun (item) {
      const { $utils, $router } = this;

      /* 判断是否有token */
      if ($utils.getToken()) {
        this.showFun = !this.showFun;
        const { id, whetherLater, putaway, type, whetherBuy } = item;
        this.actions = { id, whetherLater, putaway, type, whetherBuy, name: item.name };
      } else {
        $utils.toast({
          message: 'ログインへ',
          onClose: () => {
            $router.push({
              name: 'Login',
              params: {
                type: 1
              }
            });
          }
        });
      }
    },

    /* 修改稍后播放与分享弹层的显影状态 */
    upDate (flag) {
      this.showFun = flag;
    },

    /* 轮播图跳转至对应的详情页面 */
    jumpTo (item) {
      const { $router } = this;
      console.log(item);
      if (item.linkStatus === 0) {
        window.location.href = item.link;
      } else if (item.linkStatus === 1) {
        $router.push({
          name: 'Detail',
          query: {
            id: item.videoId
          }
        });
      }
    },

    /* 页面响应式处理 */
    onResize () {
      const { htmlWidth, swiperOptions, $refs: { swiper }, section3 } = this;
      let slidesPerView = 1.35;
      section3.span = 24;
      if (htmlWidth > 992) {
        slidesPerView = 2.35;
        section3.span = 8;
      }
      if (swiperOptions.slidesPerView === slidesPerView) {
        return false;
      }
      if (swiper) {
        swiperOptions.slidesPerView = slidesPerView;
        swiper.destroySwiper();
        swiper.initSwiper();
      }

    }
  }
};
</script>
<style scoped lang="less">
  #homePage {
    display: flex;
    flex-direction: column;
    height: 100%;

    .container {
      height: 100%;
      overflow-y: auto;

      .header {
        padding-top: 28px;
        padding-bottom: 28px;
        margin-bottom: 6px;

        .swiper-container {
          width: 100%;
          height: 296px;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          background-color: #2d2e2e;
          border-radius: 16px;
          transition: 300ms;
          transform: scale(0.85);

          .bg-cover {
            width: 100%;
            height: 100%;
          }
        }

        .swiper-slide-active,
        .swiper-slide-duplicate-active {
          transform: scale(1.05);
        }
      }

      .section {
        padding-right: 28px;
        padding-left: 28px;
        margin-bottom: 50px;

        .section-title {
          position: relative;
          justify-content: space-between;
          padding-top: 14px;
          padding-bottom: 14px;
          margin-bottom: 32px;

          &,
          .left,
          .right {
            display: flex;
            align-items: center;
          }

          .left {
            font-size: 30px;
            line-height: 40px;

            .icon {
              width: 80px;
              height: 36px;
              margin-right: 8px;

              &.icon-vr {
                background-image: url("~@/assets/images/icon_tag_03.png");
              }

              &.icon-news {
                background-image: url("~@/assets/images/icon_tag_02.png");
              }

              &.icon-hot {
                background-image: url("~@/assets/images/icon_tag_01.png");
              }
            }
          }

          .right {
            font-size: 26px;
            line-height: 34px;

            .icon-arrow {
              width: 32px;
              height: 32px;
              margin-left: 18px;
              background-image: url("~@/assets/images/icon_arrowRight_04.png");
            }
          }

          &::after {
            position: absolute;
            top: 100%;
            left: 50%;
            width: 714px;
            height: 27px;
            content: '';
            background: url("~@/assets/images/bg_line_01.png") center / contain no-repeat;
            transform: translateX(-50%);
          }

          &.no-line {
            &::after {
              display: none;
            }
          }
        }

        &.section-1 {
          .section-content {
            .list {
              display: flex;
              flex-wrap: wrap;
              margin: -12px -10px;

              .list-item {
                position: relative;
                width: 218px;
                height: 120px;
                margin: 12px 10px;

                .img {
                  width: 100%;
                  height: 100%;
                  opacity: 0.4;
                  border-radius: 8px;
                  overflow: hidden;
                }

                .title {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 9;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 18px;
                  color: #fff;
                }
              }
            }
          }
        }

        &.section-2 {
          .section-content {
            .list {
              padding-right: 16px;
              padding-left: 16px;
              margin-right: -28px;
              margin-left: -28px;

              .list-item {
                width: 240px;
                margin-right: 12px;
                margin-left: 12px;

                /deep/
                .content {
                  .name {
                    height: 80px;
                    .multi-line-omit();
                  }
                }
              }
            }
          }
        }

        &.section-3 {
          .section-content {
            .list {
              margin-right: -14px;
              margin-left: -14px;

              /deep/
              .list-item {
                width: 100%;
                padding-right: 14px;
                padding-left: 14px;
                margin-bottom: 42px;

                .thumb {
                  height: 348px;
                }

                .content {
                  .name {
                    .text-overflow();
                  }
                }
              }
            }
          }
        }
      }
    }

    .popup-giftBag-1 {
      width: 750px;
      height: 966px;
      background: url("~@/assets/images/bg_redPacket_02.png") center / cover no-repeat;

      .content {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 58px;

        .btn-confirm {
          width: 366px;
          height: 82px;
          margin-bottom: 36px;
          font-size: 32px;
          font-weight: bold;
          color: #333;
          background-color: #fac26c;
          border-radius: 16px;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
        }

        .agreement {
          width: 428px;
          margin-bottom: 122px;
          font-size: 20px;

          /deep/
          .van-checkbox {
            align-items: flex-start;

            .van-checkbox__icon {
              padding-top: 4px;
              font-size: 24px;

              .van-icon {
                width: 28px;
                height: 28px;
                background-color: #fac26c;
                border-color: #fac26c;
              }
            }

            .txt {
              flex: 1;
              line-height: 36px;
              color: #fff;
              opacity: 0.5;
            }
          }
        }

        .van-icon {
          font-size: 44px;
          color: #c4bfbf;
        }
      }
    }

    .popup-giftBag-2 {
      width: 672px;
      padding-top: 206px;
      background: none;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 36px 56px;
        background-color: #2d2d2f;
        border-radius: 16px;

        .thumb {
          width: 508px;
          height: 440px;
          margin-top: -206px;
          margin-bottom: 84px;
          background: url("~@/assets/images/img_01.png") center / contain no-repeat;
        }

        .tip {
          font-size: 24px;
        }

        .content-footer {
          display: flex;
          align-items: center;
          width: 100%;
          margin-top: 74px;

          .btn {
            height: 84px;
            border-radius: 16px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);

            &.btn-cancel {
              width: 214px;
              margin-right: 18px;
              font-size: 26px;
              color: rgba(77, 35, 35, 0.6);
              background-image: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
              border-radius: 16px;
            }

            &.btn-confirm {
              flex: 1;
              font-size: 32px;
              color: #4d2323;
              background-image: linear-gradient(180deg, #36abf5 0%, #7acbff 100%);
            }
          }
        }
      }
    }

    .popup-bindPay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 672px;
      height: 83%;
      max-height: 1118px;

      .close {
        position: fixed;
        top: 3px;
        right: 5px;
        width: 50px;
        height: 50px;
        font-size: 25px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .content {
        width: 360px;
        font-size: 30px;
        font-weight: bold;
        line-height: 86px;
        text-align: center;
      }

      .bottom {
        position: fixed;
        bottom: 39px;
        justify-self: flex-end;
        width: 80%;

        .btn {
          width: 100%;
          border-radius: 15px;
        }
      }
    }

    .popup-coupons {
      width: 670px;
      overflow: hidden;
      border-radius: 54px 54px 0 0;

      .popup-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 162px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .popup-title {
          padding-right: 46px;
          padding-left: 46px;
          font-size: 36px;
          line-height: 46px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        max-height: 700px;
        padding: 48px 36px 64px;
        margin-top: -1px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .tip {
          margin-bottom: 70px;
          font-size: 24px;
          line-height: 32px;
          opacity: 0.7;
        }

        .list {
          height: 100%;
          overflow-y: auto;

          .list-item {
            display: flex;
            align-items: center;
            height: 142px;
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: #fff;
            border-radius: 16px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .left {
              flex: 1;
              align-self: stretch;
              padding: 16px 24px;
              border-right: 2px dashed #ccc;

              .title {
                margin-bottom: 10px;
                font-size: 36px;
                font-weight: bold;
                line-height: 46px;
                color: #333;
              }

              .tip {
                font-size: 24px;
                line-height: 32px;
                color: #666;
              }
            }

            .right {
              flex-shrink: 0;
              width: 172px;
              padding-right: 8px;
              padding-left: 8px;
              font-size: 52px;
              font-weight: bold;
              color: #ee3d19;
              text-align: center;
            }
          }
        }

        .content-footer {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          margin-top: 40px;

          .btn {
            height: 82px;
            border-radius: 16px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);

            &.btn-cancel {
              width: 212px;
              margin-right: 20px;
              font-size: 26px;
              color: rgba(255, 255, 255, 0.8);
              background-color: #ff6854;
              border-radius: 16px;
            }

            &.btn-confirm {
              flex: 1;
              font-size: 32px;
              font-weight: bold;
              color: #333;
              background-color: #fac26c;
            }
          }
        }
      }
    }
  }
</style>